<template>
  <div class="admin-orders-listing">
    <title-bar
      title="Optimal car inventories"
      submitBtn
      submitBtnTitle="Confirm changes"
      @submitPressed="saveChanges"
    />
    <b-tabs
      pills
      class="order-listing-tabs"
      v-model="tabIndex"
      @input="loadOCI"
    >
      <b-tab
        v-for="(tab, tabIndex) of tabs"
        :key="tabIndex"
        :title="tab.title"
        href="#new"
      ></b-tab>
    </b-tabs>
    <oci-listing :size="size" @updateOCI="setToUpdate" ref="ociList" />
  </div>
</template>

<script>
import { BTabs } from 'bootstrap-vue'
import TitleBar from '@/components/TitleBar.vue'

import Toast from '@/components/Toast.vue'
import 'vue-good-table/dist/vue-good-table.css'
import OciListing from '@/views/Admin/OptimalCarInventories/components/OciListing.vue'

export default {
  components: {
    TitleBar,
    BTabs,
    OciListing,
  },
  data() {
    return {
      search: '',
      tabIndex: 0,
      tabs: [],
      toUpdate: [],
      size: null,
    }
  },
  created() {
    this.loadTabs()
  },
  methods: {
    loadTabs() {
      this.$Enums
        .getResourceByUrl({ url: '/enums/car_size' })
        .then(response => {
          response.data.keys.forEach(element => {
            this.tabs.push({
              title: this.$helper.getEnumTranslation('car_size', element),
              id: element,
            })
          })
        })
    },
    loadOCI(index) {
      if (index !== -1) {
        this.size = this.tabs[index].id
      }
    },
    setToUpdate(toUpdate) {
      this.toUpdate = toUpdate
    },
    updateInventory(id, available) {
      const body = {
        available,
      }
      return this.$Inventories
        .updateResource({ id, body })
        .then()
        .catch(error => {
          if (error.response) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              error.response.data['hydra:title'],
              error.response.data['hydra:description'],
              'warning',
            )
          }
        })
    },
    updateOCI(id, quantity, variant) {
      const body = {
        quantity,
        variant,
        size: this.size
      }
      return this.$OptimalCarInventories
        .updateResource({ id, body })
        .then()
        .catch(error => {
          if (error.response) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              error.response.data['hydra:title'],
              error.response.data['hydra:description'],
              'warning',
            )
          }
        })
    },
    saveChanges() {
      const promises = []
      this.toUpdate.forEach(product => {
        if (product.quantity_update !== product.quantity_original) {
          promises.push(this.updateOCI(product.ocid, product.quantity_update, product.variant))
        }
      })
      Promise.all(promises).then(results => {
        if (results.length) {
          this.$helper.showToast(
            this.$toast,
            Toast,
            'Changes saved',
            '',
            'success',
          )
          this.cart = []
          this.$refs.ociList.afterUpdate()
        }
      })
    },
  },
}
</script>
