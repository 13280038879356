<template>
  <div class="stock-card">
    <search class="mb-4" @inputChange="inputChange" />
    <div class="row">
      <div class="col-lg-3">
        <div class="categories">
          <h6 class="mb-3">Category</h6>
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                :class="{ active: selectedCategory === null }"
                @click="setCurrentCategory(null)"
                >All</a
              >
            </li>
            <li v-for="(item, index) in categories" :key="index">
              <a
                href="javascript:void(0)"
                :class="{ active: isCategorySelected(item) }"
                @click="setCurrentCategory(item)"
                >{{ item.name }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-9">
        <h6 class="mb-3">Products</h6>
        <vue-good-table
          class="custom-table-style possible-vertical-align"
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            perPage: perPage,
            setCurrentPage: currentPage,
          }"
          :row-style-class="rowStyleClass"
          @on-page-change="onPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'name'">
              <div class="product-name">
                <div v-if="props.row.fileUrl" class="product-photo">
                  <img
                    :src="`${props.row.fileUrl}`"
                    :alt="`${props.formattedRow[props.column.field]}`"
                  />
                </div>
                <span> {{ props.formattedRow[props.column.field] }}</span>
              </div>
            </span>

            <table-number-input
              v-else-if="props.column.field == 'quantity_update'"
              v-model="props.row.quantity_update"
              @input="updateToUpdate(props.row)"
              :tick="tick"
            ></table-number-input>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <table-pagination
              v-model="currentPage"
              :total="pages"
              :per-page="perPage"
              :pageChanged="props.pageChanged"
              :perPageChanged="props.perPageChanged"
            />
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import Search from '@/components/Inputs/Search.vue'
import TableNumberInput from '@/components/Inputs/TableNumberInput.vue'
import TablePagination from '@/components/Table/TablePagination.vue'

export default {
  props: {
    size: { type: String, default: null },
  },
  components: {
    VueGoodTable,
    TableNumberInput,
    TablePagination,
    Search,
  },
  watch: {
    size(_, oldSize) {
      if (oldSize) {
        this.toUpdate = []
        this.loadOci()
      }
    },
  },
  created() {
    this.loadCategories()
    this.loadProducts()
    this.loadOci()
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      perPage: 25,
      currentPage: 1,
      pages: 0,
      categories: [],
      selectedCategory: null,
      tick: 0,
      toUpdate: [],
      allOCIS: [],
      OCIS: [],
      columns: [
        {
          field: 'name',
          sortable: false,
        },
        {
          field: 'quantity_update',
          type: 'number',
          sortable: false,
          width: '130px',
        },
      ],
      rows: [],
    }
  },
  methods: {
    setOCIUpdate() {
      this.rows.forEach(p => {
        const index = this.OCIS.findIndex(o => o.variant === p['@id'])
        if (index !== -1) {
          const tuIndex = this.toUpdate.findIndex(
            tu => tu.variant === p['@id'],
          )
          if (tuIndex !== -1) {
            p.quantity_update = this.toUpdate[tuIndex].quantity_update
            p.quantity_original = this.toUpdate[tuIndex].quantity_original
          } else {
            p.quantity_update = this.OCIS[index].quantity
            p.quantity_original = this.OCIS[index].quantity
            this.toUpdate.push({
              ocid: (p.ociId = this.OCIS[index].id),
              variant: p['@id'],
              quantity_original: this.OCIS[index].quantity,
              quantity_update: this.OCIS[index].quantity,
            })
          }
        }
      })

      // trigger array change for vue
      const newRows = this.rows
      this.rows = []
      this.rows.push(...newRows)
      this.tick += 1
    },
    updateToUpdate(product) {
      const index = this.toUpdate.findIndex(
        p => p.variant === product['@id'],
      )
      if (index !== -1) {
        this.toUpdate[index].quantity_update = product.quantity_update
      }
      this.$emit('updateOCI', this.toUpdate)
    },
    afterUpdate() {
      this.toUpdate = []
      this.allOCIS = []
      this.loadOci()
    },
    loadOci() {
      if (!this.allOCIS.length) {
        this.$OptimalCarInventories
          .getCollection({
            params: { pagination: false },
          }, 'optimal_car_inventories_list')
          .then(response => {
            this.allOCIS = response.data['hydra:member']
            this.OCIS = this.allOCIS.filter(oci => this.size === oci.size)
            this.setOCIUpdate()
          })
      } else {
        this.OCIS = this.allOCIS.filter(oci => this.size === oci.size)
        this.setOCIUpdate()
      }
    },
    loadProducts() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      }
      if (this.selectedCategory !== null) {
        params['product.category'] = this.selectedCategory
      }
      if (this.search !== '') {
        params.search = this.search
      }
      this.$ProductVariants.getCollection({ params }, 'product_variant_oci_list').then(response => {
        this.rows = response.data['hydra:member']
        this.pages = response.data['hydra:totalItems']
        this.setOCIUpdate()
      })
    },
    loadCategories() {
      this.$Categories.getCollection({}, 'category_list').then(response => {
        this.categories = response.data['hydra:member']
      })
    },
    setCurrentCategory(item) {
      if (item === null) {
        this.selectedCategory = null
      } else {
        this.selectedCategory = item.id
      }
      this.loadProducts()
    },
    isCategorySelected(item) {
      if (
        item &&
        this.selectedCategory !== null &&
        item.id === this.selectedCategory
      ) {
        return true
      }
      return false
    },
    inputChange(value) {
      this.search = value
      this.loadProducts()
    },
    onPageChange() {
      if (this.tick > 0) {
        this.loadProducts()
      }
      this.tick += 1
    },
    rowStyleClass(row) {
      let styleClass = ''
      if (row.quantity_original !== row.quantity_update) {
        styleClass = 'row-changed'
      }
      return styleClass
    },
  },
}
</script>
